import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { ImageDataLike, GatsbyImage, getImage } from 'gatsby-plugin-image';
import SpotlightSectionStyled from './SpotlightSectionStyled';
import { BaseComponentProps } from '@/types';
import Reveal from '@/components/reveal/Reveal';

interface SpotlightData {
  id: string;
  contentfulSpotlightBlock: {
    title: string;
    contentText: {
      contentText: string;
    };
    link: string;
    photo: {
      gatsbyImageData: ImageDataLike;
    };
  };
}

interface SpotlightSectionProps extends BaseComponentProps {
  shouldShowHeader: boolean;
}

const query = graphql`
  query getLastSpotlightContent {
    contentfulSpotlightBlock(slug: { eq: "latest-spotlight" }) {
      title
      contentText {
        contentText
      }
      link
      photo {
        gatsbyImageData
      }
    }
  }
`;

const SpotlightSection: React.FC<SpotlightSectionProps> = ({
  shouldShowHeader,
}) => {
  const { contentfulSpotlightBlock: data }: SpotlightData =
    useStaticQuery(query);
  return (
    !!data && (
      <SpotlightSectionStyled>
        <div className="spotlight-container container">
          {shouldShowHeader && (
            <Reveal className="section-header">
              <h2 className="mini-header">Spotlight</h2>
            </Reveal>
          )}
          <div className="sections">
            <Reveal className="section">
              <div className="image-container">
                {!!data.photo && (
                  <GatsbyImage
                    image={getImage(data.photo?.gatsbyImageData)}
                    alt=""
                  />
                )}
              </div>
            </Reveal>
            <Reveal className="section">
              <div className="post-details">
                <h3 className="title">{data.title}</h3>
                <p>{data.contentText.contentText}</p>
                <Link
                  to={`${data.link}`}
                  className="read-more-container link"
                  aria-label="Read more"
                >
                  <p className="read-more">Read more</p>
                </Link>
              </div>
            </Reveal>
          </div>
        </div>
      </SpotlightSectionStyled>
    )
  );
};

export default SpotlightSection;
